import React, { Component } from 'react'
import { Navbar, Nav, DropdownButton, Dropdown, Col, Row, Button, Badge, Alert, Container, Tab, Tabs } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import axios from 'axios'
import { adminGetOrders } from '../service/orderService';
import { CodeSlash } from 'react-bootstrap-icons';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';
import dayjs from 'dayjs';
import weekOfYear from "dayjs/plugin/weekOfYear";

import AdminOrderList from '../components/admin/adminOrderList';


class UserAdminPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allOrders: [],
      selectedTab: "today"
    }
    this.filteredData = this.filteredData.bind(this)
    this.groupedByDay = this.groupedByDay.bind(this)
    dayjs.extend(weekOfYear)
  }

  // function to get data
  // length 7 month  or 13 month
  // period this week, weekly, monthly, all 12 months
  // return a set of graph data
  filteredData = async (period) => {

    let filter = "?filter[where][order_date][gt]=" + dayjs().subtract(period, 'month').toISOString();
    await adminGetOrders(filter).then((res) => {
      let sortedOrders = []
      if (res.length > 0) {
        sortedOrders = res.sort(function (a, b) {
          return new Date(a.order_date) - new Date(b.order_date);
        })
        this.setState({
          allOrders: sortedOrders,
        });
      }
    }).catch(err => {
      console.log(err)
    })
  }

  // WORKING ON:
  groupedByDay = (data, period) => {

    let is_same = ''
    let is_format = ''

    switch (period) {
      case 'today': {
        is_same = 'day';
        is_format = 'YYYY-MM-DD';
      }
        break;
      case 'monthly': {
        is_same = 'month';
        is_format = 'YYYY-MM-DD';
      }
        break;
      case 'weekly': {
        is_same = 'week';
      }
        break;

      default: {
        is_same = 'day';
        is_format = 'YYYY-MM';
      }
        break;
    }

    return data.reduce((acc, item) => {
      const itemDate = dayjs(item.order_date);
      // Find an existing group with the same day
      const existingGroup = acc.find(group => {
        // console.log('(group.order_date', group.order_date)
        return dayjs(group.order_date).isSame(itemDate, is_same)
      });

      if (existingGroup) {
        // Add the item to the existing group
        // console.log('existingGroup', existingGroup)
        existingGroup.items.push(item);
      } else {
        // Create a new group for this day
        // console.log('acc', acc)
        acc.push({
          order_date: dayjs(item.order_date).format(is_format), // Group date  // period === 'weekly'? dayjs(item.order_date).week() : 
          items: [item], // Items for this group
        });
      }

      return acc;
    }, []);
  }


  async componentDidMount() {

    this.filteredData(12)
    // let current_order_date = new Date().toISOString()
    // let 6months = new Date
    // console.log('>>>', dayjs().subtract(7, 'month').toISOString())

    // x_period_data && x_period_data.map(
    //   item =>{
    //     console.log('order_date: ', item.order_date )
    //   }
    // )

    // load 6 monthhs data
    // break downs to months
    // break downs to weeks
    // breakdown to this week





  }
  render() {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const chart_data = []


    // tab selected value

    // this.state.selectedTab === monthly

    let filter_within_7_days = dayjs().subtract(8, 'day').toISOString(); // 7days
    let x_period_data = []
    if (this.state.allOrders.length > 0) {
      x_period_data = this.state.allOrders.filter((item, index) => {

        if (this.state.selectedTab === "weekly" || this.state.selectedTab === "monthly")
          return item
        if (new Date(item.order_date) > new Date(filter_within_7_days)) {
          return item
        }
      })

      if (x_period_data.length > 0) {
        const grouped = this.groupedByDay(x_period_data, this.state.selectedTab).reverse()
        grouped.map(row => {
          let day_sum = 0
          let day_cancel = 0
          row.items.map(order => {
            if (order.payment_recived)
              day_sum += order.total_charge
            else
              day_cancel += order.total_charge
          })

          chart_data.push({
            name: this.state.selectedTab === 'weekly' ? dayjs(row.order_date).week() + '-' + dayjs(row.order_date).year() : this.state.selectedTab === 'monthly' ? dayjs(row.order_date).format('MM') : dayjs(row.order_date).format('DD/MM'), // weekday[new Date(row.order_date).getDay()],//+'/'+dayjs(row.order_date).format('DD-MM')
            paid: (day_sum / 100),
            cancel: (day_cancel / 100)
          })
        })
      }
    }

    const show_order_list = this.state.allOrders.filter(o => {

      let filter_within_7_days = dayjs().subtract(80, 'day').toISOString(); // 7days

      if (dayjs(o.order_date).isAfter(filter_within_7_days, 'day'))
        return o
    })


    return <Container fluid className='d-flex flex-column vh-100 mh-100 overflow-auto align-items-center'>
      <div style={{ textAlign: "center", margin: 20 }} >
        <h1 style={{ fontFamily: 'Lexend Deca' }}>User Admin Panel</h1>
      </div>

      <Row>
        <Col style={{ marginTop: 100 }}>
          <Tabs
            id="controlled-tab-example"
            activeKey={this.state.selectedTab}
            onSelect={(k) => this.setState({ selectedTab: k })}
            variant='tabs'


          >
            <Tab eventKey="today" title="Today" className='box-padding' >
              <BarChart width={1200} height={600} data={chart_data} margin={{top: 50, right: 20, bottom: 20, left: 20}} >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="paid" fill="#0091FF" >
                  <LabelList
                    dataKey={'paid'}
                    position="top"
                    formatter={label => {
                      return label > 5 ? label : null;
                    }}
                  />
                </Bar>
                <Bar dataKey="cancel" fill="#F22B29" >
                  <LabelList
                    dataKey={'cancel'}
                    position="top"
                    formatter={label => {
                      return label > 5 ? label : null;
                    }}
                  />
                </Bar>
              </BarChart>
            </Tab>

            <Tab eventKey="weekly" title="Weekly" className='box-padding' >
              <BarChart width={1200} height={600} data={chart_data} margin={{top: 50, right: 20, bottom: 20, left: 20}}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="paid" fill="#0091FF" >
                  <LabelList
                    dataKey={'paid'}
                    position="top"
                    formatter={label => {
                      return label > 5 ? label : null;
                    }}
                  />
                </Bar>

              </BarChart>
            </Tab>

            <Tab eventKey="monthly" title="Monthly" className='box-padding' >
              <BarChart width={1200} height={600} data={chart_data} margin={{top: 50, right: 20, bottom: 20, left: 20}}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="paid" fill="#0091FF" > <LabelList
                  dataKey={'paid'}
                  position="top"
                  formatter={label => {
                    return label > 5 ? label : null;
                  }}
                /> </Bar>

              </BarChart>
            </Tab>


          </Tabs>
        </Col>
      </Row>
      {show_order_list.length > 0 &&
        <AdminOrderList orders={show_order_list.reverse()} />
      }
    </Container>
  }

}

function mapStateToProps(state) {
  return {
    userId: state.AuthReducer.userId,
    userName: state.AuthReducer.userName,
    isLoggedIn: state.AuthReducer.isLoggedIn,
    access_token: state.AuthReducer.access_token,
  };
}
export default connect(mapStateToProps)(UserAdminPanel);
