
import { act } from 'react';
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_SETTING,
  UPLOAD_CART_SUCCESS,
  UPLOAD_CART_FAILED,
  REMOVE_GROUP_FROM_CART
} from '../actions/actionTypes';
import { PURGE, REHYDRATE } from 'redux-persist';
import Switch from 'react-bootstrap/esm/Switch';

import { configure } from '@testing-library/react';

const initialState = { hasUpload: false, myCart: [], adding: false };

const cartReducer = (state = initialState, action) => {


  let count = 1
  switch (action.type) {

    case ADD_TO_CART:
      return {
        ...state,
        myCart: [...state.myCart, action.payload],
        adding: true
      }

    case REMOVE_FROM_CART:
      return {
        ...state,
        myCart: [
          ...state.myCart.filter(
            // (product) => !count || (count -= Object.keys(product)[0] === action.payload.id)
            (product) => {
              console.log(Object.values(product)[0].id)
              //return Object.values(product)[0].id !== action.payload.id
              if (Object.values(product)[0].solo === "group") {
                return !count || (count -= Object.values(product)[0].id === action.payload.id)
              } else {
                return !count || (count -= (Object.values(product)[0].id === action.payload.id && Object.values(product)[0].session === action.payload.session))
              }
            }
          ),
        ],
        remove: true,
      };

    case REMOVE_GROUP_FROM_CART:
      return {
        ...state,
        myCart: [
          ...state.myCart.filter(
            product => {
              // !(item.age > 30 && item.city === 'Los Angeles'));
              if (action.payload.solo === "solo") {
                console.log("remove solo ")
                return Object.values(product)[0].session !== action.payload.session
              } else if (action.payload.solo === "group") {
                //  return true
                if (Object.values(product)[0].count > 1) {// working
                  console.log("remove group > not count >1 ")
                  return Object.values(product)[0].id !== action.payload.id
                } else {
                  console.log("remove group > not count =1 ")
                  return Object.values(product)[0].session !== action.payload.session
                }
              }
            }
          ),
        ],
        remove: true,
      };


    case UPDATE_CART_SETTING:{

      if(action.payload.type==='configuer')
        return{
          ...state,
          myCart: state.myCart.map((product) =>
            Object.values(product)[0].session === action.payload.item.session
              ? {
                'item': {
                  ...product.item,
                  'configuer': [ ...product.item.configuer.filter(item => Object.values(item)[0] !== action.payload.selected) ]
                }
              } : product
          )
      }

      if(action.payload.type==='extras')
      return {
        ...state,
        myCart: state.myCart.map((product) =>
          Object.values(product)[0].session === action.payload.item.session
            ? {
              'item': {
                ...product.item,
                'extras': [ ...product.item.extras.filter(item => Object.values(item)[0].name !== action.payload.selected) ]
              }
            } : product
        )
      }
    }

    case UPLOAD_CART_SUCCESS:
      return { ...state, myCart: [], adding: false, isSubmitted: true };
    case UPLOAD_CART_FAILED:
      return { ...state, myCart: action.payload, adding: false, isSubmitted: false };

    default:
      return state;
  }
}
export default cartReducer



// function updateVeryNestedField(state, action) {
//   return {
//       ...state,
//       first : {
//           ...state.first,
//           second : {
//               ...state.first.second,
//               [action.someId] : {
//                   ...state.first.second[action.someId],
//                   fourth : action.someValue
//               }
//           }
//       }
//   }
// }