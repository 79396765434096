import { Login, Logout, RegisterSuccess, SetUserData, SetUserAuth} from "../actions/actionTypes";

const initialState = { 
  isLoggedIn: false,
  userId: undefined,
  userType: undefined,
  access_token: undefined,
  userName:''
 };

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Login:
      return { ...state, isLoggedIn: true };

    case RegisterSuccess:
      return { ...state, isLoggedIn: true };

    case SetUserData:
      return { ...state, user: action.payload };

    case SetUserAuth:
      return {
        ...state,
        userType: action.payload.userType,
        access_token: action.payload.access_token,
        userId:  action.payload.userId,
        userName: action.payload.userName
      };

    case Logout:{
      localStorage.removeItem('USER_KEY');
      // localStorage.removeItem('persist:auth');
      return { ...state, userType: undefined, user: {}, access_token: undefined, isLoggedIn: false, userId:'', userName:'' };
    }

    default:
      return state;
  }
};

export default authReducer;
