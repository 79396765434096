import React, { Component } from 'react'
import { Navbar, Nav, DropdownButton, Dropdown, Col, Button, Badge, Alert, Container, Offcanvas } from 'react-bootstrap'
import { connect } from 'react-redux';
import axios from 'axios'


import { FaUserCircle, FaShoppingCart, FaBars } from 'react-icons/fa';
import Signin from '../components/modals/signin'
import { logout } from '../actions/authActions'
import { initCategories } from '../actions/productActions'

import useWindowDimensions from '../lib/useWindowDimensions'
import { getUserName } from '../service/authService';

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      userdata: {},
      categories: {},
      isAdmin:false,
      showShoppingCartModal: false,
      showUserErrorAlert: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    };

    // this.handleWindowResize = this.handleWindowResize(this)

    this.handleClose = this.handleClose.bind(this)
    this.handleShow = this.handleShow.bind(this)

    this.handleShoppingCartModalClose = this.handleShoppingCartModalClose.bind(this)
    this.handleShoppingCartModalShow = this.handleShoppingCartModalShow.bind(this)
    this.handelMakeOrder = this.handelMakeOrder.bind(this)
    this.handleSinginClose = this.handleSinginClose.bind(this)

    axios.defaults.headers.common['Authorization'] = props.access_token

    this.onShowUserErrorAlert = this.onShowUserErrorAlert.bind(this)

    this.checkAdmin = this.checkAdmin.bind(this)


  }

  //  handleWindowResize = () => {
  //   this.setState({ windowWidth: window.innerWidth })
  //   this.setState({ windowHeight: window.innerHeight })
  // }

  handleClose = () => {
    this.setState({ showModal: false })
    this.onShowUserErrorAlert()
  }

  handleSinginClose = () => {
    this.setState({ showModal: false })
  }

  handleShow = () => {
    this.setState({ showModal: true })
  }

  handleShoppingCartModalClose = () => {
    this.setState({ showShoppingCartModal: false })
  }

  handleShoppingCartModalShow = () => {
    this.setState({ showShoppingCartModal: true })
  }

  handelLogout = () => {
    this.props.logout()
    // window.location.replace("http://"+window.location.host);
  }

  checkAdmin = async(userId) => {
    await getUserName(userId)
    .then((res)=>{
      this.setState({isAdmin:res.isAdmin})
    })
  }
  

  handelMakeOrder = async (values, dispatch) => {
    //connection
    //process spiner
    // upload data

    // then get respon form data
    //if data respon 200 ok 
    //  seccess

    // else unseccess order on processing and payment require

  }

  isOrderSubmited = () => {

  }


  componentDidMount() {
    this.setState({ categories: this.props.initCategories() })
    if(this.props.isLoggedIn)
    this.checkAdmin(this.props.userId)
  }

  onShowUserErrorAlert = () => {
    this.setState({ showUserErrorAlert: true },
      () => {
        window.setTimeout(() => {
          this.setState({ showUserErrorAlert: false })
        }, 3000)
      }
    )
  }




render() {
    const { isLoggedIn, urlDirect, shopping_cart, userName, userId, user } = this.props;
    // console.log(`user>`, user)
    let cartCount = shopping_cart.myCart.length
    return (
      <>
        {this.state.showUserErrorAlert &&
          <>
            <Alert dismissible variant="danger">
              <Alert.Heading>Ooops! You are not login yet!</Alert.Heading>
              <p>Login before make an order please.</p>
            </Alert>
          </>
        }



        <Navbar bg="navbar-color" expand="lg" sticky="top" >

          <Container fluid>
            <Navbar.Brand size="sm" href="/">Flying Dragon Menu</Navbar.Brand>

            <Nav className="mr-auto">
              {isLoggedIn &&
                <DropdownButton id="dropdown-basic-button" variant="Success" title={<FaBars />} style={{ zIndex: 1000 }} >
                  <Dropdown.Item href="/">{userName}</Dropdown.Item>
                  <Dropdown.Item href="/orders">Orders</Dropdown.Item>
                  { this.state.isAdmin ?
                  <Dropdown.Item href="/admin-panel">Admin</Dropdown.Item>
                  :""}
                  {this.state.isAdmin ?
                  <Dropdown.Item href="/settings">Settings</Dropdown.Item>
                  :""}
                  <Dropdown.Item onClick={() => { this.handelLogout() }}>LogOut</Dropdown.Item>
                </DropdownButton>
              }
              {!isLoggedIn &&
                <DropdownButton id="dropdown-basic-button" variant="Secondary" title={<FaUserCircle />} href="/profile" ></DropdownButton>
                //onClick={this.handleShow}
              }
            </Nav>


            {/* {cartCount === 0 &&
            <Button href="/" variant="primary" size="sm" style={{ zIndex:99}} >
             <FaShoppingCart/>   <Badge variant="danger">{cartCount}</Badge><span className="sr-only"></span>
            </Button>
          }
          {cartCount !== 0 &&
            <Button href="/shoppingcart" variant="primary" size="sm" style={{ zIndex:99}} >
              <FaShoppingCart/>   <Badge variant="danger">{cartCount}</Badge><span className="sr-only">Shopping Cart</span>
            </Button>
          } */}

            {cartCount === 0 &&
              <Button
                href="/"
                variant="primary"
                size='md'
                style={{
                  zIndex: 99,
                  justifyContent: 'center',
                  alignContent: 'space-between',
                  alignItems: 'center',
                  padding: 4,
                  marginRight: 10,
                }} >
                <FaShoppingCart fontSize="18px" style={{ marginRight: 3 }} />
                <Badge pill className="d-inline-flex align-items-center justify-content-start" style={{ borderRadius: "25%", backgroundColor: "#ff375f" }} >{cartCount}</Badge>
              </Button>
            }


            {cartCount !== 0 &&
              <Button
                href="/shoppingcart"
                variant="primary"
                size='md'
                style={{
                  zIndex: 99,
                  justifyContent: 'center',
                  alignContent: 'space-between',
                  alignItems: 'center',
                  padding: 4,
                  marginRight: 10,
                }} >
                <FaShoppingCart fontSize="18px" style={{ marginRight: 3 }} />
                <Badge pill className="d-inline-flex align-items-center justify-content-start" style={{ borderRadius: "25%", backgroundColor: "#ff375f" }}>{cartCount}</Badge>
              </Button>
            }


          </Container>
        </Navbar>

        <Signin showModal={this.state.showModal} handleClose={this.handleClose} handleSinginClose={this.handleSinginClose} handleShow={this.handleShow} />

      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer,
    userId: state.AuthReducer.userId,
    userName: state.AuthReducer.userName,
    isLoggedIn: state.AuthReducer.isLoggedIn,
    access_token: state.AuthReducer.access_token,
    categories: state.categories,
    shopping_cart: state.shoppingCart
  };
}
const mapDispatchToProps = {
  logout,
  initCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNav)

