import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import CreateCategory from '../../components/inputs/createCategory'
import EditingCategory from '../inputs/editingCategory';


function CategoryEditingModal({ show, handleClose, handleDelete, allCategories, category, editCategory }) {

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditingCategory
          onSubmit={
            (values, dispatch) => editCategory(category.id ,values, dispatch)
          }
          initialValues={category}
          categoies={allCategories}
        />
        <br />
        <br />
        <br />
        Are you sure you want to delete <strong>{category ? category.name : ''}</strong>?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CategoryEditingModal;