import axios from 'axios';
import { IoIosSettings } from 'react-icons/io';

axios.defaults.baseURL = 'https://efun.co.nz/flying/api';

axios.defaults.headers.common['Authorization'] = '';

export const USER_KEY = "access_token";
export const USER_VALUE = '';
// export const onSignOut = () => AsyncStorage.removeItem(USER_KEY);


// export const pushuserAccess = (uid) => { 

//   return new Promise((resolve, reject) => {
//     axios({
//       method: 'post',
//       url: '/users/'+uid+'/verify', //POST /users/{id}/verify
//       headers: {
//         'Content-Type': 'application/json'
//       },
//     })
//     .then(res => {
//       if(res.status === 400 || res.status === 403  || res.status === 401 || res.status === 422){
//         reject(res);
//       }
//       axios.defaults.headers.common['Authorization'] =  res.data.id;
//       localStorage.setItem("USER_KEY", res.data.id);
//       resolve(res.data);
//     })
//     .catch(err => {
//       reject(err.response.data);
//     });
//   });

// }


export const getAccessToken = () => {
  return localStorage.getItem('USER_KEY');
}

export const loginUserAccount = (loginUser) => {

  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: '/users/login',
      headers: {
        'Content-Type': 'application/json'
      },
      data: loginUser
    })
      .then(res => {
        if (res.status === 400 || res.status === 403 || res.status === 401 || res.status === 422) {
          reject(res);
        }
        axios.defaults.headers.common['Authorization'] = res.data.id;
        localStorage.setItem("USER_KEY", res.data.id);
        console.log('user', res.data)
        resolve(res.data);
      })
      .catch(err => {
        reject(err.response.data);
      });
  });
}

export const postUserAccount = (postUser) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: '/users',
      headers: {
        'Content-Type': 'application/json'
      },
      data: postUser
    })
      .then(res => {
        axios.defaults.headers.common['Authorization'] = res.data.id;
        localStorage.setItem("USER_KEY", res.data.id);
        resolve(res.data);
      })
      .catch(err => {
        reject(err.response.data);
      });
  });
}

export const getUserName = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: '/users/' + id,
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(res => {
        if (res.status === 400 || res.status === 403 || res.status === 401 || res.status === 422) {
          reject(res);
        }
        //  console.log(`get one res`, res)
        resolve(res.data);
      })
      .catch(err => {
        reject(err.response.data);
      });
  });
}

// export const postDeviceInfo = ( device ) => {
//   return new Promise((resolve, reject) => {
//     axios({
//       method: 'post',
//       url: '/api/devices',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       data: device
//     })
//     .then(res => {
//       resolve(res.data);
//     })
//     .catch(err => {
//       reject(err.response);
//     });
//   });
// }
