
import React, { Component } from 'react'

import { Button, ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux'

class SingleProductConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ingredients: this.props.product?.config,
    }
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount() {
    // load product config state
    // this.setState({ingredients:this.props.configer})
    console.log(this.props.product)
  }


  onClick = async (pid, ing) => {
    console.log('ing',ing)
    if (this.state.ingredients[ing]) {
      this.setState({ ingredients: { ...this.state.ingredients, [ing]: false } })
      this.props.removeConfiguration({[pid]:ing})

    } else {
      this.props.addConfiguration({[pid]:ing})
      this.setState({ ingredients: { ...this.state.ingredients, [ing]: true } })
    }
  }


  render() {
    // console.log('this.props', this.props)
    return (
      <>
        <ListGroup>
          {(
            Object.keys(this.state.ingredients).map((ingredient, index) => {
              return <ListGroup.Item key={index}>
                <Button
                  size="sm"
                  key={index}
                  variant={(this.state.ingredients[ingredient] ? "success" : "secondary")}
                  className=""
                  onClick={() => this.onClick(this.props.product.id, ingredient)}>{ingredient}</Button>
              </ListGroup.Item>
            }
            )
          )}
        </ListGroup>
      </>
    )

  }
}

// function mapStateToProps(state) {
//   // console.log('state', state)
//   return{
//     productCongfigs: fig =>
//   }
// }

function mapDispatchToProps(dispatch) {
  return {
    // addConfig: config => dispatch(addConfig(config)),
    // removeConfig: config => dispatch(removeConfig(config))
  }
}

export default connect(null, mapDispatchToProps)(SingleProductConfig)

