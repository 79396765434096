
import React from 'react';

const renderTextArea = ({ input, label, meta: { touched, error } }) => {
    let hasError = false;
    if (error !== undefined) {
      hasError = true;
    }
    return (
        <div>
            <textarea {...input} placeholder="Pick up at 15 minutes later, During peak business hours, additional wait time may be required." rows="3" cols="30"/>
            {touched && ((error && <span>{error}</span>) )}
        </div>
    )
}
export default renderTextArea
