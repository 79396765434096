import {
  ADD_TO_CART, 
  REMOVE_FROM_CART, 
  UPDATE_CART_SETTING,
  UPLOAD_CART_SUCCESS, 
  UPLOAD_CART_FAILED,
  REMOVE_GROUP_FROM_CART
} from './actionTypes'


  export const addToCart = (myCart) => ({
    type: ADD_TO_CART,
    payload: myCart
  });

  export const removeFromCart = (myCart) => ({
    type: REMOVE_FROM_CART,
    payload: myCart
  });

  export const removeGroupFromCart = (myCart) => ({
    type: REMOVE_GROUP_FROM_CART,
    payload: myCart
  });
  
  
  export const updateCartSetting = (myCart) => ({
    type: UPDATE_CART_SETTING,
    payload: myCart
  });

  export const uploadCartSuccess = (myCart) => ({
    type: UPLOAD_CART_SUCCESS,
    payload: myCart
  });

  export const uploadCartFailed = (myCart) => ({
    type: UPLOAD_CART_FAILED,
    payload: myCart
  });
