import axios from 'axios';
import { connectAdvanced } from 'react-redux';

axios.defaults.baseURL = 'https://efun.co.nz/flying/api';

const instance = axios.create()
instance.interceptors.request.use((config) => {
  config.headers['request-startTime'] = new Date().getTime();
  return config
})

instance.interceptors.response.use((response) => {
  const currentTime = new Date().getTime()      
  const startTime = response.config.headers['request-startTime']      
  response.headers['request-duration'] = currentTime - startTime   
  return response
})


export const postProducts = (postCat) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: '/Products',
      headers: {
        'Content-Type': 'application/json'
      },
      data: postCat
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.response);
      });
  });
}

export const getProducts = () => {
  return new Promise((resolve, reject) => {

  instance.get('https://efun.co.nz/flying/api/Products')
  .then((response) => {      
    console.log(response.headers['request-duration'], "milliseconds")
    if (response.status === 400 || response.status === 403) {
      reject(response);
    }
    resolve(response.data);
  }).catch((error) => {
    reject(error.response);
  }) 
  });
}