import React from 'react';

const renderTextBox = ({ input, label, type, meta: { touched, error } }) => {
    let hasError = false;
    if (error !== undefined) {
      hasError = true;
    }
  
    return ( <div>
      <label>{label}</label>
      <div>
        <input {...input} placeholder={label} type={type} />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  )}

export default renderTextBox