import React, { Component } from 'react'
import { Button, ListGroup } from 'react-bootstrap';
// import {Accordion, Card, Button} from 'react-bootstrap'
// import { IoIosSettings, IoIosAddCircleOutline  } from 'react-icons/io';
import { connect } from 'react-redux'

import {
  addExtra,
  removeExtra
} from '../actions/productActions'

// pureComponent, only display data from props 
class ProductExtra extends Component {
  constructor(props) {
    super(props)
    // console.log("props > ", props)

    this.state = {
      extras: [],
      selectedExtras: [],
      reload: this.props.reset
    }

    this.onSelectExtraClick = this.onSelectExtraClick.bind(this)
  }
  
  // UNSAFE_componentWillReceiveProps({reset}) {
  //   if(reset){
  //     this.setState({ extras: this.props.extra })
  //     this.setState({ selectedExtras: [] })
  //     this.props.hadReload(false)
  //   }
  // }
  componentDidUpdate(prevProps) {
    // Check if the 'reset' prop has changed and is now true
    console.log(prevProps)
    if (this.props.reset && this.props.reset !== prevProps.reset) {
      this.setState({ 
        extras: this.props.extra, 
        selectedExtras: [] 
      });
      this.props.hadReload(false);  // Call the hadReload function
    }
  }

  componentDidMount() {
    this.setState({ extras: this.props.extra })
  }


  onSelectExtraClick = (pid, extraIngredient) => {
    console.log("extraIngredient", extraIngredient)
    // this.setState({ extras:{...this.state.extras, [extraIngredient]:true} })
    // if (this.state.selectedExtras.includes(extraIngredient.name)) {
    //   // if the item been include then exclude and turn off remove it from selectedextra
    //   //FOR COLORING
    //   this.setState({
    //     selectedExtras: [
    //       ...this.state.selectedExtras.filter(extra => extra !== extraIngredient.name)
    //     ]
    //   })
    
    //   // remove it from store
    //   this.props.removeExtra({ [pid]: extraIngredient })
    // } else {
    //   // if the item been not include then include and turn on, addto  selectedextra
    //   this.setState({ selectedExtras: [...this.state.selectedExtras, extraIngredient.name] })
    //   // add to store
    //   this.props.addExtra({ [pid]: extraIngredient })
    // }
  }

  render() {

    return (
      <>
        <ListGroup>
          {(
            this.state.extras.map((extraIngredient, index) => (
              <ListGroup.Item key={index}>
                <Button
                  size="sm"
                  key={index}
                  variant={(this.state.selectedExtras.includes(extraIngredient.name) ? "success" : "secondary")}
                  className=""
                  onClick={() => this.onSelectExtraClick(this.props.pId, extraIngredient)}
                > {
                    extraIngredient.name + '   $'+ extraIngredient.price.regular/100 
                  }
                </Button>
              </ListGroup.Item>
            )
            )
          )}
        </ListGroup>
      </>
    )

  }
}



function mapDispatchToProps(dispatch) {
  return {
    addExtra: extra => dispatch(addExtra(extra)),
    removeExtra: extra => dispatch(removeExtra(extra))
  }
}

export default connect(null, mapDispatchToProps)(ProductExtra)
