import React, { Component } from "react";
import { connect } from "react-redux";
import "../index.css";
import { Link, NavLink } from "react-router-dom";

import { Container, Row, Col, Modal, ListGroup, Accordion } from "react-bootstrap";
import Orders from '../interface/Orders';

import ProductDetails from "./productDetails";
import ProductListDetailes from "./ProductListDetailes";
import CategoryProductList from "./categoryProductList";

const styles = {
  viewlist: {
    margin: 10,
    padding: 10,
    border: '1px solid rgba(0, 0, 0, 0.2)',
    backgroundColor: "#fff"
  },
  containerStyle:{
    marginLeft:'0px !important',
    marginRight:'0px !important'
  }

}
class CategoryList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedCategory: "Deep Fried",
      renderCounter: 0,
      selectedColor: "#fff",
      selectedProduct: ""
    };
    this.selectingCat = this.selectingCat.bind(this)
    this.handelColse = this.handelColse.bind(this)
    this.handleRowClick = this.handleRowClick.bind(this)
    // Create a reference array to store refs for each row
    this.rowRefs = [];
  }

  selectingCat = (selected) => {
    this.setState({ selectedCategory: selected })
  }

  handelColse = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  getProductsFromMenu = (product, products) => {
    let temp = []
    product.add_product_menu.menu.forEach(menuName => {
      temp = temp.concat(products.filter(prod => prod.CategoryName === menuName))
    })
    return temp
  }

  filterByCategory = (produt, cate, index, any_extras, including) => {
    return <ProductListDetailes
      key={Math.random()}
      product={produt}
      extras={any_extras}
      included={including}
    ></ProductListDetailes>
  }

  // Handle row click to change background color
handleRowClick = (index) => {
  // Reset the background color of all rows
  this.rowRefs.forEach((row, i) => {
    if (row) {
      row.style.backgroundColor = i === index ? '#ff9f0a' : 'transparent';
    }
  });
};



  render() {
    const { categories, products } = this.props;
    console.log(1)
    const flag_time = localStorage.getItem("modal");
    // console.log(`flag`, flag_time) //can be value old value

    const current = new Date();

    if (!flag_time) {
      localStorage.setItem("modal", current.getHours());
      this.setState({ showModal: !this.state.showModal });
    } else {
      let display_time = current.getHours();
      // console.log(`display_time`, display_time)
      if (parseInt(display_time) > parseInt(flag_time)) {
        localStorage.removeItem("modal");
      } else if (parseInt(display_time) < parseInt(flag_time)) {
        localStorage.removeItem("modal");
      }
    }

    let all = []
    categories.map((category, indx) => {
      const productListByCategory = products.filter(product => product.CategoryName === category.name) // fist search
      productListByCategory.map((product, index) => {
        if (index === 0) {
          all.push(<Row key={Math.random()} className="menus" id={encodeURI(category.name)} >
            <h1 style={{
            paddingTop: 20,
            paddingLeft:30
          }}>{category.name}</h1></Row>)
        }
        all.push(<CategoryProductList key={product.id + "" + Math.random()} product={product}></CategoryProductList>)
      })
    })


    return (
      <Container className="list-group-container cart-item" style={styles.containerStyle} >
        <Row className="flex-grow-1 overflow-hidden vh-100 mh-100 overflow-hidden">
          <Col xs="3" style={{ padding: 0, backgroundColor: "#fff" }} className="mh-100 overflow-auto hide-scrollbar" >
            {
              categories.map((cat, index) => {
                let simple_name = ""
                switch (cat.name) {
                  case "Deep Fried":
                    simple_name = "Deep Fried"
                    break;
                  case "Burgers":
                    simple_name = "Burgers"
                    break;
                  case "Toasted Sandwiches":
                    simple_name = "Toasts"
                    break;
                  case "Fried Rice":
                    simple_name = "Fried Rice"
                    break;
                  case "Chow Mein":
                    simple_name = "Chow Mein"
                    break;
                  case "Egg Foo Yung":
                    simple_name = "Foo Yung"
                    break;
                  case "Chop Suey":
                    simple_name = "Chop Suey"
                    break;
                  case "Sweet & Sour OR Lemon":
                    simple_name = "Sweet Sour"
                    break;
                  case "Black bean OR Black Pepper":
                    simple_name = "Black bean"
                    break;
                  case "Stir Fried Noodles":
                    simple_name = "Noodles"
                    break;
                  case "Chef's Specials":
                    simple_name = "Specials"
                    break;
                  case "Special Pack":
                    simple_name = "Packs"
                    break;
                  case "Chinese Meal Combo":
                    simple_name = "Chinese Combo"
                    break;
                  default:
                    break
                }
                return  <Row
                    key={index}
                    ref={(el) => (this.rowRefs[index] = el)} // Assign ref for each row
                    href={"#" + encodeURI(cat.name)}
                    onClick={async () => {
                      this.handleRowClick(index) // HACK: useRef not setState
                      window.location.href = "#" + encodeURI(cat.name);
                      if (this.$ref && window.location.href.includes("#" + encodeURI(cat.name))) {
                        this.$ref.scrollIntoView({
                          // optional params
                          behavior: 'smooth',
                          block: 'start',
                          inline: 'center',
                        });
                      }
                    }}
                    style={{
                      paddingTop: 15,
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      border: '1px solid rgba(0, 0, 0, 0.4)',
                    }}>
                    <div className="col" style={{maxWidth:160}} >
                    <img
                      className="img-fluid"
                      style={{ borderRadius: "50%", minWidth: 30,  }}
                      src={cat.media.hero.replace(".jpg", '-600.jpg')}
                    />
                
                    <h2 className="responsive" >{simple_name}</h2>
                    </div> 
                  </Row> 
              })
            }
          </Col>
          {/* //WORKING ON: */}
          <Col style={{ backgroundColor: "#fff" }} className="mh-100 overflow-auto hide-scrollbar">
            {all}
          </Col>
        </Row>

        <Modal
          show={this.state.showModal}
          onHide={this.handelColse}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Order and Pick Up Only! </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ justifyContent: "center" }}>
              <h6 size="sm">Lunch : Wednesday to Saturday 11:30am-2:00pm </h6>
              <h6>Dinner : Monday to Sunday 4:00pm-9:00pm</h6>
              <h6 style={{ color: '#FF101F', backgroundColor: '#ff9f0a' }}>Online Orders only open up to 8PM</h6>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.categories.filter((category) => category.status === "active"),
    products: state.products.sort((a, b) => a.display_level.menu - b.display_level.menu),
  };
}

export default connect(mapStateToProps)(CategoryList);
