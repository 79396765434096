import React, { Component } from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { Col, Row, Card, Button, Container } from "react-bootstrap";

import LoginForm from "../interface/LoginForm";
import { login, setUserAuth } from "../actions/authActions";
import { loginUserAccount, postUserAccount, getUserName } from "../service/authService";
import SignUp from "../interface/SignupForm";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignUp: false,
      status: "",
    };
  }

  handelSignin = () => {
    this.setState({ isSignUp: false, status: "" });
  };

  handelSignup = () => {
    this.setState({ isSignUp: true, status: "" });
  };

  singin = async (values, dispatch) => {
    return await loginUserAccount(values).then(
      async (res) => {
        //get user name
        let name = await getUserName(res.userId)
        // await console.log(`object`, name)
        await dispatch(reset("LoginForm"));
        await this.props.setUserAuth({
          userId: res.userId,
          userType: res.usertype,
          access_token: res.id,
          userName: name.first_name
        });
        await this.props.login();
        window.location.replace("http://"+ window.location.host);
        return res;
      },
      (reject) => {
        this.setState({ status: "email passsword error, try again please!" });
      }
    );
  };

  singup = async (values, dispatch) => {
    values.username = values.email;
    return postUserAccount(values).then(
      (res) => {
        dispatch(reset("SignUp"));
        this.handelSignin();
        window.location.replace("http://"+window.location.host);
        return res;
      },
      (reject) => {
        this.setState({ status: "user already registered!" });
      }
    );
  };

  render() {
    const { isLoggedIn, access_token } = this.props;
    // console.log(`access_token`, access_token)


    return (
      <Container>
        <Row>
          <div className="whiteSpace"></div>
          <Col md></Col>
          <Col md style={{flexDirection:"row",alignItems: 'center'}}>
            {!isLoggedIn && !this.state.isSignUp && (
              <LoginForm
                onSubmit={(values, dispatch) => this.singin(values, dispatch)}
              />
            )}
            {!isLoggedIn && this.state.isSignUp && (
              <SignUp
                onSubmit={(values, dispatch) => this.singup(values, dispatch)}
              />
            )}
            <br />
            <br />
            {this.state.status}
            <br />
            <br />
            {!isLoggedIn && (
              <>
            <Button
              variant="primary"
              onClick={() => {
                this.handelSignin();
              }}
            >
              Signin
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.handelSignup();
              }}
            >
              SignUp
            </Button>
            </>)}

          </Col>
          <Col md></Col>
        </Row>
        <Row>
          <div className="whiteSpace">
            
          </div>
          </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.AuthReducer.isLoggedIn,
    access_token: state.AuthReducer.access_token,
  };
}

const mapDispatchToProps = {
  login,
  setUserAuth,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
