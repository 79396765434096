import React, { Component } from 'react'

import { Button, ListGroup } from 'react-bootstrap';
// import {Accordion, Card, Button} from 'react-bootstrap'
// import { IoIosSettings, IoIosAddCircleOutline  } from 'react-icons/io';

import { connect } from 'react-redux'

// import {
//   addExtra,
//   removeExtra
// } from '../actions/productActions'

// pureComponent, performance update , when close return a array of swelected values
//TODO: accept(product, (callbackfunction return the selected extra) ) make sure only click on and show with one prduct and only on this dom do the work
class SingleProductExtras extends Component {
  constructor(props) {
    super(props)
    this.state = {
      extras: this.getExtras(this.props.product),
      selectedExtras:[]  

    }
  }
  
    getExtras = (product) => {
      let extras = []
      if (product.add_product_menu && product.add_product_menu.hasOwnProperty('menu')) {
        product.add_product_menu.menu.forEach(menuName => {
          extras = extras.concat(this.props.products.filter(prod => prod.CategoryName === menuName))    // secon search 
        })
      }
      return extras
    }
  
    // getIncluded = (product) => {
    //   let included = []
    //   if (product.include_products && product.include_products.hasOwnProperty('menu')) {
    //     product.include_products.menu.forEach(include_products => {
    //       included = included.concat(include_products)
    //     })
    //   }
    //   return included
    // }



  onSelectExtraClick = async (pid, extraIngredient) => {

    if (this.state.selectedExtras.includes(extraIngredient.name)) {
      console.log('same name>', extraIngredient.name)
    // if the item been include then exclude and turn off remove it from selectedextra
    //FOR COLORING
    await this.setState({
      selectedExtras: [
        ...this.state.selectedExtras.filter(extra => extra !== extraIngredient.name)
      ]
    })
    this.props.removeExtra(pid, extraIngredient)

  }else{
     //FOR COLORING
    this.setState({ selectedExtras: [...this.state.selectedExtras, extraIngredient.name] })
        // parent function add to extra state
    this.props.addExtras(pid, extraIngredient)
  }


    // this.props.addExtra({ [pid]: extraIngredient })
    // if (this.state.selectedExtras.includes(extraIngredient.name)) {
    //   // if the item been include then exclude and turn off remove it from selectedextra
    //   //FOR COLORING
    //   this.setState({
    //     selectedExtras: [
    //       ...this.state.selectedExtras.filter(extra => extra !== extraIngredient.name)
    //     ]
    //   })
    //   // remove it from store
    //   this.props.removeExtra({ [pid]: extraIngredient })
    // } else {
    //   // if the item been not include then include and turn on, addto  selectedextra
    //   this.setState({ selectedExtras: [...this.state.selectedExtras, extraIngredient.name] })
    //   // add to store
    //   this.props.addExtra({ [pid]: extraIngredient })
    // }

  }

  render() {

    return (
      <>
        <ListGroup>
          {(
            this.state.extras.map((extraIngredient, index) => (
              <ListGroup.Item key={index}>
                <Button
                  size="sm"
                  key={index}
                  variant={(this.state.selectedExtras.includes(extraIngredient.name) ? "success" : "secondary")}
                  className=""
                  onClick={() => this.onSelectExtraClick(this.props.product.id, extraIngredient)}
                > {
                    extraIngredient.name + '   $'+ extraIngredient.price.regular/100 
                  }
                </Button>
              </ListGroup.Item>
            )
            )
          )}
        </ListGroup>
      </>
    )

  }
}

function mapStateToProps(state) {
  return {
    products: state.products
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // addExtra: extra => dispatch(addExtra(extra)),
    // removeExtra: extra => dispatch(removeExtra(extra))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleProductExtras)
