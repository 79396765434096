import React, { Component } from 'react'
// import { Field, reduxForm } from 'redux-form'
// import renderTextBox from './renderTextBox'
// import { Form, Button, Spinner } from 'react-bootstrap'
import { reset } from 'redux-form'
import CreateProduct from '../../components/inputs/createProduct'

import { postCategory, getCategories } from '../../service/categoryService'
import { postProducts, getProducts } from '../../service/productService'

import { Container, Row, Col, ListGroup, Tabs, Tab } from 'react-bootstrap'



class EditProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: null,
      products: null,
      activeProduct:false
    }

  }

  async componentDidMount() {

    let incomeCategories = await getCategories();
    if (incomeCategories)
      this.setState({ categories: incomeCategories })

    let incomeProductas = await getProducts();
    if (incomeProductas)
      this.setState({ products: incomeProductas })

  }


  handel_product_active = () => {
    this.setState({activeProduct:!this.state.activeProduct})
  }

  createProduct = async (values, dispatch) => {
// console.log(this.state.activeProduct)
    // console.log(values)
    let jsonObj = {};
    if (values.configer.length){
      values.configer.map( conf=>{
        if(conf)
        jsonObj[conf] = true;
      })
     
    }
//  values.config = jsonObj

    // if (values.config)
    //   values.config = JSON.parse(values.config)
    // if (values.price)
    //   values.price = JSON.parse(values.price);
      values['config'] = jsonObj
      values['status'] = {'active':this.state.activeProduct}

      values['add_product_menu']={'menu':[values.add_on_menu]}
      values.price.regular = parseFloat(values.price.regular).toFixed(2) *100
      values.display_level.menu = parseInt( values.display_level.menu)

    // // console.log('values.display_level', values.display_level)
    // if (values.include_products)
    //   values.include_products = JSON.parse(values.include_products)
    // if (values.display_level)
    //   values.display_level = JSON.parse(values.display_level)
    // if (values.data)
    //   values.data = JSON.parse(values.data)
    // if (values.add_product_menu)
    //   values.add_product_menu = JSON.parse(values.add_product_menu)
    delete values['configer']
    delete values['add_on_menu']
    console.log(values)

    // return postProducts(values).then(
    //   res => {
    //     dispatch(reset('CreateProducts'))
    //     console.log(res)
    //     return res
    //   },
    //   reject => {
    //   }
    // )

  }


  render() {
    return (
      <Container>
        <div style={{ textAlign: "center", margin: 20 }} >
          <h4 style={{ fontFamily: 'Lexend Deca' }}>Add New Product</h4>
        </div>

        <Row>
          <Col>
            <CreateProduct onSubmit={(values, dispatch) => this.createProduct(values, dispatch)} categoies={this.state.categories} productActive={this.handel_product_active}/>
          </Col>
        </Row>
      </Container>
    )
  }
}
export default EditProduct