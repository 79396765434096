import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import renderTextBox from './renderTextBox'
import { Form, Button, Spinner } from 'react-bootstrap'




class CreateCategory extends Component {

  render() {
    const { error, handleSubmit, submitting, categoies } = this.props
    return (
      <Form onSubmit={handleSubmit}>
        <Field
          name="name"
          type="text"
          component={renderTextBox}
          label="Name"

        />
        <Field
          name="description"
          type="text"
          component={renderTextBox}
          label="description"
        />
        <Field
          name="list_order"
          type="number"
          component={renderTextBox}
          label="List Order"
        />
         <br />
         <label>Status:  &nbsp;</label>
        <Field
          name="status"
          component="select"
          label="status"
        >
          <option value={'active'}>active</option>
          <option value={'disable'} >disable</option>
          </Field>
          <label className="h10">* disable not show on Menu</label>
        <br/>
        <br />
        <label>Parent Category:  &nbsp;</label>
  
        <Field name="parent_id" component="select">

        {categoies && categoies.map((cat, idx) => (
          <option key={idx} value={cat.id} defaultValue={idx===0?"selected":''}>{cat.name}</option>
        ) )}
       </Field> 
       <br />
       <label className='h10'>*if no categories from then inherited from ROOT </label>

        <br />

        <Field
          name="media.hero"
          type="text"
          component={renderTextBox}
          label="media"
        />
         <label >* path for image must ./static/Chinese-Meal-Combo.jpg </label>
        <br />
        {error && <strong>{error}</strong>}
        <Button type="submit" disabled={submitting}
          style={{ marginTop: 10 }}>
          {submitting ? <Spinner animation="border" variant="danger" /> : "Save New"}
        </Button>

      </Form>
    )
  }
}
export default reduxForm({
  form: 'CreateCategory', // a unique identifier for this form
})(CreateCategory)
