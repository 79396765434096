import React, { Component } from "react";
import {
  Accordion,
  Card,
  Button,
  Row,
  Col,
  Container,
  Modal,
  Image,
  Badge,
  ButtonGroup,
  ButtonToolbar
} from "react-bootstrap";
import { FaPlus, FaMinus, FaChevronDown } from "react-icons/fa";
import { connect } from "react-redux";
import * as R from "ramda";
import uuid from "react-uuid";

// import ProductConfigable from "./productConfigable";
import SingleProductConfig from './singleProductConfig';
// import ProductExtra from "./productExtra";
import SingleProductExtas from "./singleProductExtas";

import ProductInclude from "./productInclude";
import { addToCart, removeFromCart } from "../actions/cartActions";
// import { clearConfig } from "../actions/productActions";
import { StarFill, HeartFill, Heart, PlusCircle, DashCircle } from 'react-bootstrap-icons';
// import { addConfig } from "../actions/productActions";


class CategoryProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetConfigs: false,
      resetExtras: false,

      extraProducts: [],      // init list
      includedProducts: [],   // init list

      showModal: false,
      totalItemCartCount: 0,

      addedExtras: [], // selected Items
      addedConfig: []
    };

    this.addtoCartOnClick = this.addtoCartOnClick.bind(this);
    // this.didConfigReload = this.didConfigReload.bind(this);
    // this.didExtraReload = this.didExtraReload.bind(this);
    this.renderSwitch = this.renderSwitch.bind(this);
    this.getExtras = this.getExtras.bind(this);
    this.getIncluded = this.getIncluded.bind(this);

    this.addToExtras = this.addToExtras.bind(this)
    this.removeFromExtra = this.removeFromExtra.bind(this)

    this.addToConfig = this.addToConfig.bind(this)
    this.removeFromConfig = this.removeFromConfig.bind(this)
  }

  //=========================================
  addToExtras = async (pid, selectedExtras) => {
    await this.setState({ addedExtras: [...this.state.addedExtras, { [pid]: selectedExtras }] })
    console.log('this.state.addedExtras+', this.state.addedExtras)
  }

  removeFromExtra = (pid, selectedExtras) => {
    this.setState({
      addedExtras: [
        ...this.state.addedExtras.filter(extra => (Object.values(extra)[0].name !== selectedExtras.name))
      ]
    })
    console.log('this.state.addedExtras-', this.state.addedExtras)
  }
  //========================================

  addToConfig = async (selectedConfig) => {

    //WORKING ON:
    await this.setState({
      addedConfig: [
        ...this.state.addedConfig.filter(config => (JSON.stringify(config) !== JSON.stringify(selectedConfig)))
      ]
    })
    console.log('this.state.addedConfig-', this.state.addedConfig)
  }

  removeFromConfig = async (selectedConfig) => {
    console.log('selectedConfig>', selectedConfig)
    await this.setState({ addedConfig: [...this.state.addedConfig, selectedConfig] })
    console.log('this.state.addedConfig+', this.state.addedConfig)
  }
  //========================================

  addtoCartOnClick = (selectedProduct) => {

    // count how many of this item if no config
    if ((this.state.addedConfig.length === 0 && this.state.addedExtras.length === 0)) {

      this.props.addToCart({
        "item": {
          id: selectedProduct.id,
          solo: 'group', // if config empty then group up
          count: 1,
          configuer: this.state.addedConfig,
          extras: this.state.addedExtras,
          carttime: new Date().toLocaleString(),
          price: selectedProduct.price.regular,
          name: selectedProduct.name,
          category: selectedProduct.CategoryName,
          session: uuid(),
        },
      });
    } else {
      this.props.addToCart({
        "item": {
          id: selectedProduct.id,
          solo: 'solo', // if config empty then group up
          count: 1,
          configuer: this.state.addedConfig,
          extras: this.state.addedExtras,
          carttime: new Date().toLocaleString(),
          price: selectedProduct.price.regular,
          name: selectedProduct.name,
          category: selectedProduct.CategoryName,
          session: uuid(),
        },
      });
    }

    // this.props.clearConfig(pId.id);
    // clear all extra and config state
    this.setState({ addedExtras: [] })
    this.setState({ addedConfig: [] })
    //laste reload child state
    // this.setState({ resetConfigs: !this.state.resetConfigs });
    // this.setState({ resetExtras: !this.state.resetExtras });

  };

  removeItemFromCart = (productId) => {
    // console.log('remioving product ID:', productId)
    this.props.removeFromCart(productId)
  }

  // didConfigReload = (reloaded) => {
  //   this.setState({ resetConfigs: reloaded });
  // };

  // didExtraReload = (reloaded) => {
  //   console.log('reloaded',reloaded)
  //   this.setState({ resetExtras: reloaded });
  // };

  componentDidMount() {
    // when dom mount on collect those data for Modal display
    this.setState({ extraProducts: this.getExtras(this.props.product) });
    this.setState({ includedProducts: this.getIncluded(this.props.product) });

  }

  getExtras = (product) => {
    let extras = []
    if (product.add_product_menu && product.add_product_menu.hasOwnProperty('menu')) {
      product.add_product_menu.menu.forEach(menuName => {
        extras = extras.concat(this.props.products.filter(prod => prod.CategoryName === menuName))    // secon search 
      })
    }
    return extras
  }

  getIncluded = (product) => {
    let included = []
    if (product.include_products && product.include_products.hasOwnProperty('menu')) {
      product.include_products.menu.forEach(include_products => {
        included = included.concat(include_products)
      })
    }
    return included
  }

  // "DONE:" call Modal, modal error
  renderSwitch = (product, shoppingCart) => {

    const hasItem = shoppingCart.myCart.filter((x) => Object.values(x)[0].id === product.id).length



    switch (product.CategoryName) {
      case "Deep Fried":
      case "Burgers":
      case "Toasted Sandwiches":
      case "Sweet & Sour OR Lemon":
      case "Black bean OR Black Pepper":
      case "Chef's Specials":
      case "Chinese Meal Combo":
      case "Special Pack":
        return (<Col style={{ margin: 8 }}>
          <Row >
            <Col
              onClick={() => { this.setState({ showModal: !this.state.showModal }) }}
              className="d-flex justify-content-between">
              <h6>{product.name}</h6>
              <h6>{"$" + product.price.regular / 100}</h6>
            </Col>
          </Row>

          <Row
            className="d-flex justify-content-between"
          >

            <Col
              xs={8}
              style={{ margin: 0, padding: 0 }}
              className="d-flex justify-content-start align-top"
            >
              {/* <label className="h10">
                this is a very nice chip
              </label> */}
            </Col>

            <Col
              xs={2}
              className="d-flex justify-content-end "
              style={{ marginTop: 5, marginRight: 3, padding: 0 }}
            >
              <ButtonToolbar >
                <ButtonGroup size="lg">
                  <Button
                    className="d-inline-flex align-items-center justify-content-start"
                    style={{ backgroundColor: "#fff", color: "#2e3a54", marginRight: 8, padding: 0 }}
                    disabled={hasItem > 0 ? false : true}
                    onClick={() => this.removeItemFromCart({ id: product.id, session: product.session, solo: product.solo })}
                  >
                    <DashCircle size={22} />
                  </Button >

                  <Badge
                    className="d-inline-flex align-items-center justify-content-start"
                    style={{ borderRadius: "8px", backgroundColor: "#70d6ff", color: "#1c1c1e", fontSize: '14px' }} >
                    {hasItem > 0 ? hasItem : 0}
                  </Badge>

                  <Button
                    className="d-inline-flex align-items-center justify-content-start"
                    style={{ backgroundColor: "#fff", color: "#2e3a54", marginLeft: 8, padding: 0 }}
                    onClick={() => this.addtoCartOnClick(product)}
                  >
                    <PlusCircle size={22} />
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>

        </Col>);
      default:
        return (<Col style={{ margin: 8 }}>
          <Row >
            <Col
              onClick={() => { this.setState({ showModal: !this.state.showModal }) }}
              className="d-flex justify-content-between "
            >
              <h6>{product.name + " " + product.CategoryName}</h6>
              <h6>{"$" + product.price.regular / 100}</h6>
            </Col>
          </Row>
          <Row
            className="d-flex justify-content-between"
          >
            <Col
              xs={8}
              style={{ margin: 0, padding: 0 }}
              className="d-flex justify-content-start align-top"
            >
              {/* <label className="h7">
                this is a very nice chip
              </label> */}
            </Col>
            <Col
              xs={2}
              className="d-flex justify-content-end "
              style={{ marginTop: 5, marginRight: 3, padding: 0 }}
            >
              <ButtonToolbar aria-label="Toolbar with button groups">
                <ButtonGroup size="lg">
                  <Button
                    className="d-inline-flex align-items-center justify-content-start"
                    style={{ backgroundColor: "#fff", color: "#2e3a54", marginRight: 8, padding: 0 }}
                    disabled={hasItem > 0 ? false : true}
                    onClick={() => this.removeItemFromCart(product.id)}
                  >
                    <DashCircle size={28} />
                  </Button >

                  <Badge className="d-inline-flex align-items-center justify-content-start"
                    style={{ borderRadius: "8px", backgroundColor: "#70d6ff", color: "#1c1c1e", paddingLeft: 10, fontSize: '14px' }} >
                    {hasItem > 0 ? hasItem : 0}
                  </Badge>

                  <Button
                    className="d-inline-flex align-items-center justify-content-start"
                    style={{ backgroundColor: "#fff", color: "#2e3a54", marginLeft: 8, padding: 0 }}
                    onClick={() => this.addtoCartOnClick(product)}
                  >
                    <PlusCircle size={28} />
                  </Button>
                </ButtonGroup>
              </ButtonToolbar></Col>
          </Row>
        </Col>);
    }
  };

  render() {
    const { product, shoppingCart, products, categories } = this.props;

    let isConfig = R.isNil(product.config);
    const handleClose = () => {
      this.setState({ showModal: false });
    };

    return (
      <>
        <Row className="menus align-items-start" style={{ height: 80 }} >
          {/* <Col xs="2" className="text-center" style={styles.productImageBox} >  
            <Image 
              roundedCircle
              fluid
              width="80%"
              src={"./static/Deep-Fried-600.jpg"}
            />
          </Col> */}
          {this.renderSwitch(product, shoppingCart)}
        </Row>


        {/*  "WORKING ON:" the close button error */}
        <Modal
          show={this.state.showModal}
          onHide={handleClose}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{product.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ justifyContent: "center" }}>
              {product.images && (
                <>
                  <img
                    className="img-fluid"
                    src={
                      product.images ? product.images.hero : "/static/hero.jpg"
                    }
                  />
                  <p size="sm">
                    ALL PICTURES SHOWN ARE FOR ILLUSTRATION PURPOSES ONLY.
                  </p>
                </>
              )}
            </Row>

            <Container>
              <Row>
                <Col>
                  Come-with
                  {!isConfig && (
                    <SingleProductConfig
                      uuid={uuid()}
                      product={product}
                      addConfiguration={this.addToConfig}
                      removeConfiguration={this.removeFromConfig}
                    // hadReload={() => this.didConfigReload()}
                    ></SingleProductConfig>
                  )}


                  {this.state.includedProducts && (
                    <ProductInclude
                      uuid={uuid()}
                      configer={this.state.includedProducts}
                      pId={product.id}
                    ></ProductInclude>
                  )}

                </Col>

                <Col>
                  Extra
                  <SingleProductExtas
                    product={product}
                    addExtras={this.addToExtras}
                    removeExtra={this.removeFromExtra}
                  ></SingleProductExtas>

                </Col>
              </Row>
            </Container>

          </Modal.Body>
          <Modal.Footer>
            GREEN color is selected
            <Button
              variant="link"
              size="mds"
              onClick={() => {
                this.addtoCartOnClick(product);
                // handleClose();
              }}
            >
              <FaPlus /> Add To Cart
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

// function mapStateToProps(state) {
function mapStateToProps(state) {
  return {
    shoppingCart: state.shoppingCart,
    productCongfigs: state.productCongfigs,
    categories: state.categories.filter((category) => category.status === "active"),
    products: state.products.sort((a, b) => a.display_level.menu - b.display_level.menu),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // clearConfig: (clear) => dispatch(clearConfig(clear)),
    addToCart: (product) => dispatch(addToCart(product)),
    removeFromCart: (product) => dispatch(removeFromCart(product))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductList);

