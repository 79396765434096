import React, { Component } from 'react';
import {
  Accordion,
  Card,
  Button,
  Row,
  Col,
  Container,
  Modal,
} from "react-bootstrap";

export default class Verify extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isSignUp: false,
      status: "",
    };

    // const token = this.props.match.params.access_token
    // console.log('token', token)
  }

  clickOnActivation = () => { 
    const query = new URLSearchParams(this.props.location.search);
    const access_token = query.get('access_token')
    const token = query.get('token')
    const uid = query.get('uid')
    console.log('>access_token', access_token)//123
    console.log('>token', token)//123
    console.log('>uid', uid)//123




  }



  render() {
    

    return (<div>
      <Row>
        <Col></Col>
        <Col xs={5}>
        <Card  style={{marginTop:'200px'}}>
          <Card.Body>
            <Card.Title className="mt-2">
            Activation
            </Card.Title>
            <Card.Text>
            This page will automatically reload and redirect to click
            </Card.Text>
            <Button variant="primary" onClick={this.clickOnActivation}>Activate</Button>
          </Card.Body>
        </Card>
        </Col>
        <Col></Col>

      </Row>

    </div>)
  }

}