import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Card,
  Accordion,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";
import { getOrders, updateOrderPaymentStatus } from "../../service/orderService";
import { connect } from "react-redux";
import queryString from "query-string";

class AdminOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrders: this.props.orders,
      showPaymentStatus: false,
      showModal: false,
      selectedOrder: null,
    };
    this.onShowPaymentStatusAlert = this.onShowPaymentStatusAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
    this.beautifyNames = this.beautifyNames.bind(this);
  }

  onShowPaymentStatusAlert = () => {
    this.setState({ showPaymentStatus: true }, () => {
      window.setTimeout(() => {
        this.setState({ showPaymentStatus: false });
      }, 1115000);
    });
  };

  onCloseAlert = () => {
    this.setState({ showPaymentStatus: false });
  };

  onOpenOrderModal = (selected) => {
    this.setState({ selectedOrder: selected });
    this.setState({ showModal: true });
  };

  beautifyNames = (categoryName, productName) => {
    switch (categoryName) {
      case "Deep Fried":
      case "Burgers":
      case "Sweet & Sour OR Lemon":
      case "Black bean OR Black Pepper":
      case "Chef's Specials":
      case "Chinese Meal Combo":
      case "Special Pack":
        return productName;
      default:
        return productName + " " + categoryName;
    }
  }


  async componentDidMount() {
    // const values = queryString.parse(this.props.location.search);

    // if (values.response) {
    //   this.onShowPaymentStatusAlert();
    //   let status = "";
    //   if (values.response === "Failure") {
    //     status = {
    //       payment: { poli: true },
    //       payment_status: "Failure",
    //       payment_recived: "false",
    //     };
    //   } else if (values.response === "Success") {
    //     status = {
    //       payment: { poli: true },
    //       payment_status: "Success",
    //       payment_recived: "true",
    //     };
    //   } else {
    //     status = {
    //       payment: { poli: true },
    //       payment_status: "Cancelled",
    //       payment_recived: "false",
    //     };
    //   }

    //   await updateOrderPaymentStatus(values.id, status).then(
    //     (res) => {
    //       setTimeout(function () {
    //         window.location.href = '/orders'
    //       }, 5000);
    //     },
    //     (rej) => {
    //       window.location.href = '/orders'
    //     }
    //   );
    // }

    // await getOrders(this.props.user.userId).then(
    //   (res) => {
    //     // console.log(`res.string`, res.stringify())
    //     this.setState({
    //       allOrders: res.sort(
    //         (a, b) => new Date(b.order_date) - new Date(a.order_date)
    //       ),
    //     });
    //   },
    //   (rej) => { }
    // );
  }

  
  render() {
    // let sum = 0;
    // const qvalue = queryString.parse(this.props.location.search);
    return (
      <Container >

        <br />
        <h2>Your Recent Orders</h2>
        <br />
        <hr />
        <Row className="row-buttom" style={{ margin: 0, padding: 0 }}>
          <Col xs={5} style={{ textAlign: "center" }}>
            <label className="h8">Date:</label>
          </Col>
          <Col xs={2} style={{ textAlign: "center" }}>
            <label className="h8">ID:</label>
          </Col>
          <Col xs={2} style={{ textAlign: "center" }}>
            <label className="h8">Cost:</label>
          </Col>
          <Col xs={2} style={{ textAlign: "center" }}>
            <label className="h8">Payment Status:</label>
          </Col>
        </Row>

        {this.state.allOrders.map((order, index) => {
          return (
            <Row
              key={index}
              className="row-buttom"
              onClick={() => this.onOpenOrderModal(order)}
              style={{
                backgroundColor:
                  order.payment_status === "Cancelled"
                    ? "#fbe9e7"
                    : order.payment_status === "ready"
                      ? "#fffde7"
                      : order.payment_status === "Success"
                        ? "#c8e6c9"
                        : order.payment_status === "Failure"
                          ? "#ffebee"
                          : "#e0e0e0",

              }}
            >
              <Col xs={5} style={{ textAlign: "center" }} >
                <label className="h10">
                  {new Date(order.order_date).toLocaleString(
                    "en-NZ",
                    "New Zealand/Auckland"
                  )}
                </label>
              </Col>
              <Col xs={2} style={{ textAlign: "center" }}>
                <label className="h9">{"#" + order.order_no}</label>
              </Col>
              <Col xs={2} style={{ textAlign: "center" }}>
                <label className="h9"> {"$" + order.total_charge.toFixed(2) / 100}</label>
              </Col>
              <Col xs={2} style={{ textAlign: "center" }}>
                <label className="h9">{order.payment_status}</label>
              </Col>
            </Row>
          );
        })}

    
      </Container>
    );
  }
}

const cartStyle = {
  marginTop:'0.8rem !important',
  minHeightheight: 120,
  backgroundColor: "#FDFFFC",
  padding: 0,
  margin: '10px !important',
  borderRadius: 8,
  border: '1px solid rgba(0, 0, 0, 0.05)',
  boxShadow: "0 3px 10px rgb(0 0 0 / 0.3)"
};

function mapStateToProps(state) {
  return {
    user: state.AuthReducer,
  };
}
export default connect(mapStateToProps)(AdminOrderList);
