import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import CreateCategory from '../../components/inputs/createCategory'

function CategoryNewModal({ show, handleClose, allCategories, createCategory }) {
  return (
    <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Create New Category</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <CreateCategory
    onSubmit={(values, dispatch) => createCategory(values, dispatch)} 
    categoies={allCategories} />
    <br/>
    <br/>
    <br/>
      </Modal.Body>
    </Modal>
  );
}

export default CategoryNewModal;