import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import renderTextBox from './renderTextBox'
import { Form, Button, Spinner } from 'react-bootstrap'
//media.hero: "./static/Chinese-Meal-Combo.jpg"

class EditingCategory extends Component {

  render() {
    const { error, handleSubmit, submitting, categoies } = this.props
    // console.log(initialValues)
    return (
      <Form onSubmit={handleSubmit}>
        <Field
          name="name"
          type="text"
          component={renderTextBox}
          label="Name"
        />

        <Field
          name="description"
          type="text"
          component={renderTextBox}
          label="description"
        />

        <Field
          name="list_order"
          type="number"
          component={renderTextBox}
          label="List Order"
        />

        <label>Status:  &nbsp;</label>
        <Field
          name="status"
          component="select"
          label="status"
        >
          <option value={'active'}>active</option>
          <option value={'disable'} >disable</option>
        </Field>
        <label className="h10">* disable not show on Menu</label>
        <br />
        <br />
        <label>Parent Category:  &nbsp;</label>

        <Field name="parent_id" component="select">

          {categoies && categoies.map((cat, idx) => (
            <option key={idx} value={cat.id} defaultValue={idx === 0 ? "selected" : ''}>{cat.name}</option>
          ))}
        </Field>
        <br />
        <label >*if no categories from then inherited from ROOT </label>

        <Field
          name="media.hero"
          type="text"
          component={renderTextBox}
          label="media"
        />
        <label >* path for image must ./static/Chinese-Meal-Combo.jpg </label>
        <br />

        {error && <strong>{error}</strong>}
        <Button type="submit" disabled={submitting}
          style={{ marginTop: 10 }}>
          {submitting ? <Spinner animation="border" variant="danger" /> : "Update"}
        </Button>

      </Form>
    )
  }
}

EditingCategory = reduxForm({
  form: 'EditingCategory', // a unique identifier for this form
  enableReinitialize: true,
})(EditingCategory)

export default EditingCategory