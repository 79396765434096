export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN'
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE'

export const FETCH_CATEGORIES_BEGIN = 'FETCH_CATEGORIES_BEGIN'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE'

export const Login = "LOGIN";
export const Logout = "LOGOUT";
export const Register = "REGISTER";
export const RegisterSuccess = "REGISTER_SUCCESS";

export const SetUserData = 'SET_USER_DATA';
export const SetUserAuth = 'SET_USER_AUTH';

export const LoginAsCustomer = 'LOGIN_AS_CUSTOMER';
export const LoginAsStoreOwner = 'LOGIN_AS_STORE_OWNER';
export const LoginAsStoreManager = 'LOGIN_AS_STORE_MANAGER';
export const LoginAsStoreWorker = 'LOGIN_AS_STORE_WORKER';
export const LoginAsStoreAdmin = 'LOGIN_AS_STORE_ADMIN';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_CART_SETTING = 'UPDATE_CART_SETTING';
export const UPLOAD_CART_SUCCESS = 'UPLOAD_CART_SUCCESS';
export const UPLOAD_CART_FAILED = 'UPLOAD_CART_FAILED';
export const REMOVE_GROUP_FROM_CART = 'REMOVE_GROUP_FROM_CART';

export const ADD_CONFIG = 'ADD_CONFIG';
export const REMOVE_CONFIG = 'REMOVE_CONFIG';

export const ADD_EXTRA = 'ADD_EXTRA';
export const REMOVE_EXTRA = 'REMOVE_EXTRA';

export const CLEAR_CONFIG = 'CLEAR_CONFIG';


export const OrderSubmiting = 'ORDER_SUBMITING';
export const OrderSubmited = 'ORDER_SUBMITED';
export const OrderPaymentSuccess = 'ORDER_PAYMENT_SUCCESS';
export const OrderPaymantFailure = 'ORDER_PAYMENT_FAILURE';
export const OrderPaymentOnhold = 'ORDER_PAYMENT_ONHOLD';
export const OrderPaymentProcessing = 'ORDER_PAYMENT_Processing';



