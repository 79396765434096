import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import { Container, Row, Col } from 'react-bootstrap'
import configureStore from './store/configStore'
import { initProducts } from './actions/productActions'


import { PersistGate } from 'redux-persist/es/integration/react';
// import ProductList from './components/productList'

import TopNav from './interface/TopNav'
import Settings from './interface/Settings'
import Orders from './interface/Orders'
import Profile from './interface/Profile'
import ShoppingCart from './interface/ShoppingCart'
import NotFound from './interface/Notfound';
import './App.css'
import CategoryList from './components/categoryList';
import Products from './interface/Products'
import UserAdminPanel from './interface/userAdminPanel'

import Verify from './interface/Verify'
const { store, persistor } = configureStore();

store.dispatch(initProducts())


// app start with loading all data from this store
// then check login status
// if login 
// else login

//  import products component for this store
//    dispaly the account ststus
// app init


class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      prevUri: '',
      currentUri: '',
      nextUri: '',
      windowWidth:window.innerWidth,
      windowHeight:window.innerHeight
    }
    this.handelURL = this.handelURL.bind(this)
  }
  //callback from chrild url
  handelURL = (newUri) => {
    this.setState({ nextUri: newUri })
  }

  

  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>

          <Router>
            <Container fluid className='d-flex flex-column vh-100 mh-100 overflow-hidden' >
              <TopNav urlDirect={this.handelURL} />
              <Switch>

                <Route path="/verify" component={Verify} />

                <Route exact path="/">
                <CategoryList></CategoryList>
                </Route>

                <Route path="/settings" component={Settings} />

                <Route path="/orders" component={Orders} />

                <Route path="/profile" component={Profile} />

                <Route path="/shoppingcart" component={ShoppingCart} />

                <Route path="/admin-panel" component={UserAdminPanel} />

                {/* <Route path="/category" component={Products} /> */}
                {/* <Route exact path="/:name" component={Products} /> */}



                <Route path='*' component={NotFound} />
                
              
              </Switch>
            </Container>
          </Router>

        </PersistGate>
      </Provider>
    )
  }
}

export default App

//orders?id=5ec1c746a39cd321f512d7e0&order_no=45134&response=Failure&token=fD1%2fPAAfaPtE%2bP6cAv74tqXRxpoLV7lx