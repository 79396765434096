import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Field, reduxForm } from 'redux-form'
// import renderTextBox from './renderTextBox'
// import { Form, Button, Spinner } from 'react-bootstrap'
import { reset } from 'redux-form'
import { postCategory, getCategories } from '../service/categoryService'
import { postProducts, getProducts } from '../service/productService'

import { Container, Row, Col, ListGroup, Tab, Nav, Modal } from 'react-bootstrap'

import EditCategory from '../components/inputs/editCategory'
import EditProduct from '../components/inputs/editProduct'
import EditingProduct from '../components/inputs/editingProduct';


class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: null,
      selectedKey: null,
      products: null,
      activeProduct: false,
      showModal: false,
      selectedEditingProduct: null,
    }
    this.handelColse = this.handelColse.bind(this)
  }

  handel_product_active = () => {
    this.setState({ activeProduct: !this.state.activeProduct })
  }

  handel_editing_product = (product) => {
    this.setState({ showModal: true, selectedEditingProduct: product })
  }


  handelColse = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleDeleteProduct = async (dispatch) => {
    // await  delCategory(this.state.selectedEditingCategory.id)
    this.handelColse()
  }


  updateProduct = async (values, dispatch) => {

    // console.log(this.state.activeProduct)
    // console.log(values)
    let jsonObj = {};
    if (values.configer.length) {
      values.configer.map(conf => {
        if (conf)
          jsonObj[conf] = true;
      })
    }
    //  values.config = jsonObj

// [{abc:true, csd:true}]
//  [abc,csd]

    // if (values.config)
    //   values.config = JSON.parse(values.config)
    // if (values.price)
    //   values.price = JSON.parse(values.price);
    values['config'] = jsonObj
    values['status'] = { 'active': this.state.activeProduct }

    values['add_product_menu'] = { 'menu': [values.add_on_menu] }
    values.price.regular = parseFloat(values.price.regular).toFixed(2) * 100
    values.display_level.menu = parseInt(values.display_level.menu)

    // // console.log('values.display_level', values.display_level)
    // if (values.include_products)
    //   values.include_products = JSON.parse(values.include_products)
    // if (values.display_level)
    //   values.display_level = JSON.parse(values.display_level)
    // if (values.data)
    //   values.data = JSON.parse(values.data)
    // if (values.add_product_menu)
    //   values.add_product_menu = JSON.parse(values.add_product_menu)
    delete values['configer']
    delete values['add_on_menu']
    console.log(values)

    // return postProducts(values).then(
    //   res => {
    //     dispatch(reset('CreateProducts'))
    //     console.log(res)
    //     return res
    //   },
    //   reject => {
    //   }
    // )


  }



  async componentDidMount() {

    let incomeCategories = await getCategories()
    if (incomeCategories)
      this.setState({ categories: incomeCategories })

    let incomeProductas = await getProducts()

    if (incomeProductas)
      this.setState({
        products: incomeProductas.sort(function (a, b) {
          return new Date(a.display_level.menu) - new Date(b.display_level.menu);
        })
      })
    // console.log("category", this.state.categories)
  }

  render() {
    const product_by_category = this.state.products && this.state.products.filter(product => {
      if (this.state.selectedKey === product.CategoryName) {
        return product
      }
    })
    
    return (
      <Container className='d-flex flex-column overflow-auto '>
        <div style={{ textAlign: "center", margin: 20 }} >
          <h1 style={{ fontFamily: 'Lexend Deca' }}>Categories and Product Setting</h1>
        </div>

        <Tab.Container id="left-tabs-example" defaultActiveKey="Product" onSelect={(k) => { this.setState({ selectedKey: k }) }}>
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="Categories">Categories</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Product">New Product</Nav.Link>
                </Nav.Item>

                {
                  this.state.categories && this.state.categories.map(
                    category => {
                      if (category.list_order !== 0)
                        return <Nav.Item key={category.id}>
                          <Nav.Link eventKey={category.name}>{category.name}</Nav.Link>
                        </Nav.Item>
                    }
                  )
                }

              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                <Tab.Pane eventKey="Categories"><EditCategory></EditCategory></Tab.Pane>
                <Tab.Pane eventKey="Product"><EditProduct></EditProduct></Tab.Pane>
                {
                  this.state.categories && this.state.categories.map(
                    category => {
                      if (category.list_order !== 0)
                        return <Tab.Pane key={category.id} eventKey={category.name}>

                          <Row className="row-buttom" style={{ margin: 0, padding: 0 }}>
                            <Col xs={1} style={{ textAlign: "center" }}>
                              <label className="h8">display_level:</label>
                            </Col>
                            <Col xs={4} style={{ textAlign: "center" }}>
                              <label className="h8">Name</label>
                            </Col>

                            <Col xs={4} style={{ textAlign: "center" }}>
                              <label className="h8">Price:</label>
                            </Col>
                            <Col xs={1} style={{ textAlign: "center" }}>
                              <label className="h8">Status:</label>
                            </Col>

                          </Row>
                          {product_by_category && product_by_category.map(
                            product => (
                              <Row
                                key={product.id}
                                className="row-buttom"
                                onClick={() => this.handel_editing_product(product)}
                                style={{
                                  backgroundColor: product.status === "disable" ? "#ffebee" : "#c8e6c9"
                                }}
                              >

                                <Col xs={1} style={{ textAlign: "left" }} >
                                  <label className="h10">
                                    {product.display_level.menu}
                                  </label>
                                </Col>
                                <Col xs={4} style={{ textAlign: "left" }}>
                                  <label className="h9">{product.name}</label>
                                </Col>
                                <Col xs={2} style={{ textAlign: "left" }} >
                                  <label className="h10">
                                    {(product.price.regular / 100).toFixed(2)}
                                  </label>
                                </Col>

                                <Col xs={2} style={{ textAlign: "left" }} >
                                  <label className="h10">
                                    {product.status.active ? "active" : "disable"}
                                  </label>
                                </Col>   {/* */}

                              </Row>))}
                        </Tab.Pane>
                    }
                  )
                }


              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

        <Modal
          show={this.state.showModal}
          onHide={this.handelColse}
          size='lg'


        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditingProduct
              productActive={this.handel_product_active}
              initialValues={this.state.selectedEditingProduct}
              categories={this.state.categories}
              handleDelete={this.handleDeleteProduct}
              onSubmit={
                (values, dispatch) => this.updateProduct(this.state.selectedEditingProduct.id, values, dispatch)
              }
            ></EditingProduct>
          </Modal.Body>

        </Modal>
      </Container>
    )
  }
}
export default Settings

// show={this.state.setDeleteDialog}
// handleClose={this.handleEditCategoryDialogClose}
// allCategories={this.state.categories}
// category = {this.state.selectedEditingCategory}
// editCategory={this.editCategory}
// handleDelete = {this.handleDeleteCategory}