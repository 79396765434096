import React, { Component } from 'react'
// import { Field, reduxForm } from 'redux-form'
// import renderTextBox from './renderTextBox'
// import { Form, Button, Spinner } from 'react-bootstrap'
import { reset } from 'redux-form'
import CreateCategory from '../../components/inputs/createCategory'
import { postCategory, getCategories, delCategory, editingCategory } from '../../service/categoryService'

import { Container, Row, Col, ListGroup, Button } from 'react-bootstrap'
import CategoryEditingModal from '../modals/categoryEditingModal'
import CategoryNewModal  from '../modals/categoryNewModal'

class EditCategory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: null,
      setDeleteDialog:false,
      selectedEditingCategory:null,
      newCategoryDialog:false
    }

    this.edit_onclick = this.edit_onclick.bind(this)

    this.handleEditCategoryDialogClose = this.handleEditCategoryDialogClose.bind(this)
    this.handleEditCategoryDialogOpen = this.handleEditCategoryDialogOpen.bind(this) 
    this.handleDeleteCategory = this.handleDeleteCategory.bind(this)

    this.handleNewCategoryDialogClose = this.handleNewCategoryDialogClose.bind(this)
    this.handleNewCategoryDialogOpen = this.handleNewCategoryDialogOpen.bind(this)

  }

  async componentDidMount() {
    let incomeCategories = await getCategories()
    if (incomeCategories) {
      this.setState({ categories: incomeCategories.sort(
        (a, b) => a.list_order - b.list_order
      ) })
    }
  }

  handleEditCategoryDialogClose = () => this.setState({ setDeleteDialog: false });
  handleEditCategoryDialogOpen = () => this.setState({ setDeleteDialog: true });


  handleNewCategoryDialogClose = () => this.setState({ newCategoryDialog: false });
  handleNewCategoryDialogOpen = () => this.setState({ newCategoryDialog: true });

  handleDeleteCategory = async(dispatch) => {
    await  delCategory(this.state.selectedEditingCategory.id)
    this.handleEditCategoryDialogClose()
  }


  edit_onclick=(category)=>{
    this.handleEditCategoryDialogOpen()
    this.setState({selectedEditingCategory:category})
  }


  createCategory = async (values, dispatch) => {
    return postCategory(values).then(
      res => {
        dispatch(reset('CreateCategory'))
        this.setState({newCategoryDialog:false})
        return res
      },
      reject => {
      }
    )
  }

  editCategory = async (id, values, dispatch) => {
    return editingCategory(id, values).then(
      res => {
        dispatch(reset('EditingCategory'))
        this.setState({setDeleteDialog:false})
        return res
      },
      reject => {
      }
    )
  }



  render() {

    return (
      <Container>
        <div style={{ textAlign: "center", margin: 20 }} >
          <h4 style={{ fontFamily: 'Lexend Deca' }}>Categories Setting</h4>
        </div>

        <Button onClick={()=>this.handleNewCategoryDialogOpen()} >New Category</Button>

        <Row className="row-buttom" style={{ margin: 0, padding: 0 }}>
          <Col xs={1} style={{ textAlign: "center" }}>
            <label className="h8">List Order:</label>
          </Col>
          <Col xs={4} style={{ textAlign: "center" }}>
            <label className="h8">Name:</label>
          </Col>
          <Col xs={1} style={{ textAlign: "center" }}>
            <label className="h8">Status:</label>
          </Col>
          <Col xs={4} style={{ textAlign: "center" }}>
            <label className="h8">Parent Name:</label>
          </Col>

        </Row>
        {this.state.categories && this.state.categories.map((category, index) => {
          return (
            <Row
              key={index}
              className="row-buttom"
              onClick={() => this.edit_onclick(category)}
              style={{
                backgroundColor: category.status === "disable" ? "#ffebee" : "#c8e6c9"
              }}
            >
              <Col xs={1} style={{ textAlign: "center" }}>
                <label className="h9">{category.list_order}</label>
              </Col>
              <Col xs={4} style={{ textAlign: "left" }} >
                <label className="h10">
                  {category.name}
                </label>
              </Col>

              <Col xs={1} style={{ textAlign: "center", backgroundColor: category.status === "disable" ? "#ffebee" : "#c8e6c9" }}>
                <label className="h9"> {category.status}</label>
              </Col>
              <Col xs={4} style={{ textAlign: "center" }}>
                <label className="h9">{
                  this.state.categories && this.state.categories.filter(parent => parent.id === category.parent_id)[0]?.name
                }</label>
              </Col>
            </Row>
          );
        })}


        <Row>
          <Col style={{ textAlign: "left", margin: 20 }}>
            {/* <CreateCategory onSubmit={(values, dispatch) => this.createCategory(values, dispatch)} categoies={this.state.categories} /> */}
          </Col>

        </Row>
  
        <CategoryNewModal
          show={this.state.newCategoryDialog}
          handleClose={this.handleNewCategoryDialogClose}
          allCategories={this.state.categories}
          createCategory={this.createCategory}
        />

        <CategoryEditingModal
          show={this.state.setDeleteDialog}
          handleClose={this.handleEditCategoryDialogClose}
          allCategories={this.state.categories}
          category = {this.state.selectedEditingCategory}
          editCategory={this.editCategory}
          handleDelete = {this.handleDeleteCategory}
        />

      </Container>
    )
  }
}
export default EditCategory